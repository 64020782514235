

import { Component, Watch } from 'vue-property-decorator';
import ImportComponent from '../components/ImportComponent.vue';
import EmploymentHelper from '../components/EmploymentHelper';
import { CurrentUser, Employment } from '../store/models';

@Component
export default class ImportEmployment extends ImportComponent {

  private helper: EmploymentHelper = new EmploymentHelper();

  protected isFieldsLoaded(): boolean {
    return (!this.helper.isLoading);
  }

  protected mounted() {
    this.loadSchemas();
    this.helper.populateDataLists(this.currentOrg);
  }

  protected getConvertedJsonRecord(record: any): any {
    const employment: Employment = {
      expense: {
        expenseClass: 'employment',
        expenseType: this.getExpenseType(record.hasProject),
        expenseKind: record.expenseKind ? record.expenseKind : '',
        expenseId: 'N/A',
      },
      diversity: {
        hasCommunityAffiliation: record.hasCommunityAffiliation ? record.hasCommunityAffiliation : '',
        diversityCode: record.diversityCode ? record.diversityCode : '',
      },
      employment: {
        isTraining: false,
        occupation: record.occupation ? record.occupation : '',
        totalEmployees: 1,
        hourlyRate: record.hourlyRate ? this.cleanseCurrencyField(record.hourlyRate) : 1,
        hoursPerDay: this.cleanseNumericField(record.hoursPerDay),
        daysWorked: 1,
      },
      payment: {
        description: record.employmentComment ? record.employmentComment : '',
        amount: this.cleanseCurrencyField(record.employmentValue),
        currency: 'CAD',
        effectiveDate: this.toIsoDateString(record.employmentDate),
        hasPayer: this.$store.getters['local/CURRENT_ORG'],
        hasProject: record.hasProject ? record.hasProject : '',
        hasSubmitter: (this.$store.getters['session/CURRENT_USER'] as CurrentUser).userinfo.identifier,
      },
    };
    // console.log(JSON.stringify(employment));
    return employment;
  }

  protected getSchemas(): string[] {
    return [ 'general', 'create' ];
  }

  protected getFields(): any[] {
    return [
      this.getField('employmentDate', 'Date'),
      this.getField('hasCommunityAffiliation', 'Indigenous Association', this.getOptionsFromShortRecords(this.helper.ics)),
      this.getField('hasProject', 'Project', this.getOptionsFromShortRecords(this.helper.projects), false, true),
      this.getField('expenseKind', 'Type', this.getOptionsFromRefData(this.helper.expenseKinds)),
      this.getField('hourlyRate', 'Rate'),
      this.getField('hoursPerDay', 'Hours Worked', [], false, true),
      this.getField('employmentValue', 'Gross Wages', [], false, true),
      // this.getField('isTraining', 'Training Position?', [], true),
      this.getField('occupation', 'Occupation', this.getOptionsFromRefData(this.helper.jobFocuses)),
      this.getField('employmentComment', 'Comment'),
      this.getField('diversityCode', 'Diversity.me code'),
    ];
  }

  protected getType(): string {
    return 'Employment';
  }

  protected getSchemaType(): string {
    return 'employment';
  }

  protected getFinishedUrl(): string {
    if (this.$route.params.id && this.$route.path.startsWith('/projects')) {
      return '/projects/id/' + this.$route.params.id + '/employment';
    } else {
      return this.getDefaultFinishedUrl();
    }
  }

  @Watch('helper.isLoading')
  private onHelperIsLoadingChanged(val: any, oldVal: any) {
    this.launchIfLoaded();
  }

  private getExpenseType(hasProject: string|null): string {
    return (hasProject && hasProject !== '') ? 'project-employment' : 'general-employment';
  }
}
