import { getShortList, getReferenceData } from '../store/rest.service';
import { ResourceSearchResult, ReferenceData, ShortRecord } from '../store/models';
import { handleError } from '../lib/shared';

export default class EmploymentHelper {

  public arrErrors: Error[] = [];
  public isLoading: boolean = true;

  public ics: ShortRecord[]|null = null;
  public icsLoading: boolean = false;

  public projects: ShortRecord[]|null = null;
  public projectsLoading: boolean = false;

  public jobFocuses: ReferenceData|null = null;
  public jobFocusesLoading: boolean = false;

  public currencies: ReferenceData|null = null;
  public currenciesLoading: boolean = false;

  public expenseKinds: ReferenceData|null = null;
  public expenseKindsLoading: boolean = false;

  public setIsLoading(): void {
    this.isLoading = (
      this.icsLoading
      || this.projectsLoading
      || this.jobFocusesLoading
      || this.currenciesLoading
      || this.expenseKindsLoading
    );
  }

  public populateDataLists(currentOrg: string) {
    this.getProjects(currentOrg);
    this.getIndigenousCommunities();
    this.getJobFocusReferenceData();
    this.getCurrencyReferenceData();
    this.getExpenseKindsReferenceData();
  }

  public getCurrencyReferenceData() {
    if (!this.currencies) {
      this.currenciesLoading = true;
      getReferenceData('currencies')
      .then((response) => {
        this.currencies = response;
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.currenciesLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getExpenseKindsReferenceData() {
    if (!this.expenseKinds) {
      this.expenseKindsLoading = true;
      getReferenceData('expenseKind-employment')
      .then((response) => {
        this.expenseKinds = response;
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.expenseKindsLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getIndigenousCommunities() {
    if (!this.ics) {
      this.icsLoading = true;
      getShortList('IndigenousCommunity')
      .then((response) => {
        this.ics = (response as ResourceSearchResult).searchResults.results as ShortRecord[];
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.icsLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getProjects(currentOrg: string, force: boolean = false) {
    if (!this.projects || force) {
      this.projectsLoading = true;
      const whereClauses: Array<[string, string[]]> = [ [ 'project.ownerVendor', [ currentOrg ] ] ];
      getShortList('Project', false, whereClauses, false, 'poId')
      .then((response) => {
        this.projects = (response as ResourceSearchResult).searchResults.results as ShortRecord[];
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.projectsLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getJobFocusReferenceData() {
    if (!this.jobFocuses) {
      this.jobFocusesLoading = true;
      getReferenceData('employment-types')
      .then((response) => {
        this.jobFocuses = response;
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.jobFocusesLoading = false;
        this.setIsLoading();
      });
    }
  }

  public handleError(error: any) {
    handleError(this.arrErrors, error);
  }
}
